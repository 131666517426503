*,
*:before,
*:after {
    box-sizing: inherit;
}

:root {
    --navbar-height: 6.4rem;
    --study-navbar-height: 8.8rem;
    --footer-height: 40rem;
    --sidebar-expanded-width: 23.5rem;
    --sidebar-collapsed-width: 7rem;

    color-scheme: light dark;
    font-family: var(--knowt-font-name);
    -webkit-tap-highlight-color: transparent;
}

:root[data-theme="dark"] {
    color-scheme: dark;
}

html {
    font-size: 62.5%;
    box-sizing: border-box;
    overscroll-behavior: none;
}

@media (max-width: 1420px) {
    html {
        font-size: 58.75%;
    }
}

@media (max-width: 1320px) {
    html {
        font-size: 55%;
    }
}

@media (max-width: 600px) {
    :root {
        --sidebar-collapsed-width: 0rem;
    }
}

body {
    margin: 0;
    padding: 0;
    background-color: var(--color-background);
    color: var(--color-neutral-black);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.6rem;
    overscroll-behavior: none;
    scrollbar-color: var(--color-neutral-1) transparent;
}

@media (max-width: 600px) {
    html,
    body {
        overscroll-behavior: auto;
    }
}

/********** OVERRIDE DEFAULT DOM OPINIONATED STYLE **********/
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-top: 0;
    margin-bottom: 0;
}

button {
    user-select: none;
}

input:-webkit-autofill {
    border: #112212 !important;
    -webkit-text-fill-color: #000000 !important;
    background-color: var(--color-background) !important;
    -webkit-box-shadow: 0 0 0px 50px var(--color-background) inset;
    transition: background-color 5000s ease-in-out 0s;
}

[data-theme="dark"] input:-webkit-autofill {
    border: #ffffff !important;
    -webkit-text-fill-color: #ffffff !important;
    background-color: var(--color-background) !important;
    -webkit-box-shadow: 0 0 0px 50px var(--color-background) inset;
    transition: background-color 5000s ease-in-out 0s;
}

/* to remove the two arrows on number input */
input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/********* COLORS *********/
html {
    --color-background: #f7f8fa;

    --color-primary: #50d2c2;
    --color-primary-light: #daf4f1;
    --color-primary-dark: #15504a;
    --color-primary-2: #daf4f1;
    --color-primary-3: #ffcd1f;
    --color-primary-4: #416d97;

    --color-neutral-white: #ffffff;
    --color-neutral-black: #092522;

    --color-pure-white: #ffffff;
    --color-pure-black: #000000;

    --color-neutral-1: #e7e7e7;
    --color-neutral-2: #b3b6b5;
    --color-neutral-3: #4a4a4a;

    --color-neutral-overlay: #d9d9d9;

    --color-docs-light: #e2edf6;
    --color-docs-med: #a4cae9;
    --color-docs-dark: #2888d7;

    --color-flashcards-light: #f6eee2;
    --color-flashcards-med: #efcd93;

    --color-assignment-light: #e2f9f1;
    --color-assignment-med: #8de4c4;

    --color-video-light: #f6e2e2;
    --color-video-med: #e38585;
    --color-video-dark: #bc3232;

    --color-audio: #4a4a4a;
    --color-audio-pill: #caefef;

    --color-pdf-light: #ede2f6;
    --color-pdf-med: #ccaee4;
    --color-pdf-dark: #873fc2;

    --color-excel-light: #ebf2df;
    --color-excel-med: #bdd496;

    --color-ppt-light: #fceadf;
    --color-ppt-med: #e5ad8d;

    --color-icon-1: #416d97;
    --color-icon-2: #ffc009;
    --color-icon-3: #fd7a7a;
    --color-icon-4: #e2edf6;
    --color-icon-5: #888888;

    --color-type-1: #092522;
    --color-type-2: #7c7c7c;
    --color-type-3: #bfbfbf;
    --color-type-4: #4f79e5;

    --color-subject-1: #f6f0e2;
    --color-subject-2: #e2ecf6;
    --color-subject-3: #f6e2e2;
    --color-subject-4: #ece2f6;
    --color-subject-5: #e2f6e8;

    --color-mc-study-1-light: #f6e2f0;
    --color-mc-study-1-med: #e7b0d6;
    --color-mc-study-1-dark: #c83e80;

    --color-mc-study-2-light: #ede2f6;
    --color-mc-study-2-med: #ceb0e7;
    --color-mc-study-2-dark: #823abb;

    --color-mc-study-3-light: #e2f1fa;
    --color-mc-study-3-med: #a7d4f0;
    --color-mc-study-3-dark: #2875ad;

    --color-mc-study-4-light: #eef8f6;
    --color-mc-study-4-med: #bce4dc;
    --color-mc-study-4-dark: #26a697;

    --color-mc-study-5-dark: #8b6a00;

    --color-success-primary: #50d2c2;
    --color-success-secondary: #eef8f6;

    --color-error-primary: #f35353;
    --color-error-secondary: #f8efef;

    --color-popup-gradient: linear-gradient(180deg, #e1eafe 36.79%, #faeee9 100%);
    --color-blue-gradient: linear-gradient(180deg, #e8f4fc 0%, #e8f4fc 23.2%, #e8f4fc 47.49%, #fff 94.68%);
    --color-purple-gradient: linear-gradient(180deg, #ecd4f6 0%, #f4deef 23.2%, #fbe6ea 47.49%, #fff 94.68%);
    --color-lightpurple-gradient: linear-gradient(180deg, #ffffff 0%, #e8def8 20%, #e8def8 80%, #ffffff 100%);
    --color-orange-gradient: linear-gradient(180deg, #fcf2e8 0%, #fdf5ee 23.2%, #fdf5ee 47.49%, #fff 94.68%);
    --color-red-gradient: linear-gradient(180deg, #fce8e8 0%, #fde9e9 23.2%, #fde9e9 47.49%, #fff 94.68%);
    --color-ps-timeline-gradient: linear-gradient(180deg, #f4f4f4 65.95%, #fff 110%);
    --color-ai-gradient: linear-gradient(90deg, #fde8e8 0%, #dfc7ff 100%);
    --color-ai-gradient-flip: linear-gradient(90deg, #dfc7ff 0%, #fde8e8 100%);
    --color-lightblue-gradient: linear-gradient(180deg, #c3efff 0%, #ffffff 100%);

    --color-interactive-border: #28c3e1;
    --color-create-button-blue-hover: #28c3e1;
    --color-metrics-button-dark-hover: #3b4847;
    --color-endscreen-button-lightblue-hover: #dcf9ff;
    --color-ai: #bc87fe;

    --color-plus-plan: #ffe99e;
    --color-limitless-plan: #ecd4ff;

    --color-orange-background: #fcf2e8;

    --color-help-instant: #f6e1a3;

    /* GAMIFICATION */
    --color-streak-dark: #ff7c1d;
    --color-streak-light: #ffeee2;
    --color-freeze-light: #75e1ff;
    --color-revival-light: #fe9099;
    --color-level-light: #f0e9ff;
    --color-coin-light: #fff3e5;
    --color-disabled-streak-button-hovered: #efefef;

    --color-warning-yellow: #ffc048;

    /* SCHEME */
    --color-scheme-primary: #4f378a;
    --color-scheme-secondary: #e8def8;
}

[data-theme="dark"] {
    --color-background: #1d1e1e;

    --color-primary: #50d2c2;
    --color-primary-light: #daf4f1;
    --color-primary-dark: #15504a;
    --color-primary-2: #15504a;
    --color-primary-3: #ffcd1f;
    --color-primary-4: #afcbe7;

    --color-neutral-white: #282929;
    --color-neutral-black: #e7e7e7;

    --color-pure-white: #ffffff;
    --color-pure-black: #000000;

    --color-neutral-1: #5b5b5b;
    --color-neutral-2: #7c7c7c;
    --color-neutral-3: #e9e9e9;

    --color-neutral-overlay: #1b1b1b;

    --color-docs-light: #345068;
    --color-docs-med: #336995;

    --color-flashcards-light: #5f5341;
    --color-flashcards-med: #a1824d;

    --color-assignment-light: #4b5e56;
    --color-assignment-med: #6d8f7c;

    --color-video-light: #524343;
    --color-video-med: #b14848;

    --color-audio: #4a4a4a;
    --color-audio-pill: #164a4a;

    --color-pdf-light: #564365;
    --color-pdf-med: #875bab;
    --color-pdf-dark: #873fc2;

    --color-excel-light: #4f5a3b;
    --color-excel-med: #79944b;

    --color-ppt-light: #604739;
    --color-ppt-med: #9a6446;

    --color-icon-1: #416d97;
    --color-icon-2: #ffc009;
    --color-icon-3: #fd7a7a;
    --color-icon-4: #e2edf6;
    --color-icon-5: #888888;

    --color-type-1: #092522;
    --color-type-2: #7c7c7c;
    --color-type-3: #bfbfbf;
    --color-type-4: #4f79e5;

    --color-subject-1: #49412c;
    --color-subject-2: #354759;
    --color-subject-3: #5c3e3e;
    --color-subject-4: #463c50;
    --color-subject-5: #3a5241;

    --color-mc-study-1-light: #604c5a;
    --color-mc-study-1-med: #372c34;
    --color-mc-study-1-dark: #ffcdf0;

    --color-mc-study-2-light: #574c60;
    --color-mc-study-2-med: #322c37;
    --color-mc-study-2-dark: #e3c0ff;

    --color-mc-study-3-light: #4c5b64;
    --color-mc-study-3-med: #2c353a;
    --color-mc-study-3-dark: #b8e8ff;

    --color-mc-study-4-light: #586260;
    --color-mc-study-4-med: #333938;
    --color-mc-study-4-dark: #a1f8e6;

    --color-mc-study-5-dark: #ffe996;

    --color-success-primary: #50d2c2;
    --color-success-secondary: #3f635c;

    --color-error-primary: #f35353;
    --color-error-secondary: #553a3a;

    --color-popup-gradient: linear-gradient(180deg, #061b35 36.79%, #778596 100%);
    --color-blue-gradient: linear-gradient(180deg, #3376a6 0%, #282929 94.68%);
    --color-orange-gradient: linear-gradient(180deg, #af7d4b 0%, #282929 94.68%);
    --color-purple-gradient: linear-gradient(180deg, #bc87fe 0%, #725893 47.34%, #282929 94.68%);
    --color-lightpurple-gradient: linear-gradient(180deg, #6a5d99 0%, #4a3962 0%, #4a3962 100%);
    --color-red-gradient: linear-gradient(180deg, #f4abab 0%, #282929 94.68%);
    --color-ps-timeline-gradient: linear-gradient(180deg, #1e1e1e 65.95%, #282929 100%);
    --color-ai-gradient: linear-gradient(90deg, #f8c9c9 0%, #a775e7 100%);
    --color-ai-gradient-flip: linear-gradient(90deg, #a775e7 0%, #f8c9c9 100%);
    --color-lightblue-gradient: linear-gradient(180deg, #1a5a72 0%, #1c1c1c 100%);

    --color-interactive-border: #147b8f;
    --color-create-button-blue-hover: #2887c3;
    --color-metrics-button-dark-hover: #d3d3d3;
    --color-endscreen-button-lightblue-hover: #003844;
    --color-ai: #a35efa;

    --color-orange-background: #282929;

    --color-help-instant: #f6e1a3;

    /* GAMIFICATION */
    --color-streak-dark: #ffeee2;
    --color-streak-light: #b56429;
    --color-freeze-light: #1182a2;
    --color-revival-light: #b0454e;
    --color-level-light: #cbb3ff;
    --color-coin-light: #ffdcb3;
    --color-disabled-streak-button-hovered: #3f3f3f;

    --color-plus-plan: #ffe99e;
    --color-limitless-plan: #ecd4ff;

    --color-warning-yellow: #ffc048;

    /* SCHEME */
    --color-scheme-primary: #c4b3f0;
    --color-scheme-secondary: #322445;
}

/********** TYPOGRAPHY **********/
.heading1 {
    font-size: 5rem;
    font-weight: 600;
    line-height: 6.4rem;
    font-weight: 600;
}

.heading2 {
    font-size: 4.4rem;
    font-weight: 600;
    line-height: 5.8rem;
    font-weight: 600;
}

.heading3 {
    font-size: 3.5rem;
    font-weight: 600;
    line-height: 4.6rem;
    font-weight: 600;
}

.heading4 {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 3.4rem;
    font-weight: 600;
}

.heading5 {
    font-size: 2rem;
    font-weight: 600;
    line-height: 2.6rem;
    font-weight: 600;
}

.body1 {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.8rem;
}

.body2 {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 2.6rem;
}

.bodyXl {
    font-size: 2.2rem;
    font-weight: 400;
    line-height: 3.2rem;
}

.bodyBold1 {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 2.8rem;
}

.bodyBold2 {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2.6rem;
}

.bodyBoldXl {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 3.2rem;
}

.secondaryText1 {
    font-size: 1.4rem;
    color: var(--color-neutral-3);
    font-weight: 400;
    line-height: 2rem;
}

.secondaryText2 {
    font-size: 1.2rem;
    color: var(--color-neutral-3);
    font-weight: 400;
    line-height: 1.6rem;
}

.secondaryTextBold1 {
    font-size: 1.4rem;
    color: var(--color-neutral-black);
    font-weight: 700;
    line-height: 2rem;
}

.secondaryTextBold2 {
    font-size: 1.2rem;
    color: var(--color-neutral-3);
    font-weight: 600;
    line-height: 1.6rem;
}

.tertiaryText1 {
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6rem;
}

.tertiaryTextBold1 {
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.6rem;
}

/********** STRIP DOM HANDY COMMON CLASS **********/
.strippedList,
.strippedList ul,
.strippedList ol,
.strippedList li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.strippedLink,
.strippedLink:visited {
    text-decoration: none;
    color: inherit;
}

.strippedBtn {
    all: unset;
    cursor: pointer;
}

.strippedAnchor,
.strippedAnchor:link,
.strippedAnchor:visited,
.strippedAnchor:hover,
.strippedAnchor:active {
    text-decoration: none;
    color: inherit;
}

.knowtLink {
    color: var(--color-primary-4) !important;
    text-decoration: underline !important;
}

.bold {
    font-weight: 600;
}

.ellipsisText {
    /**
     * ellipsis text minimum property,
     * the tag/parent-tag that uses this just have to set the width on when it's to overflow itself
     */
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.clampText {
    /**
     * "ellipsis text after n line" minimum property,
     * the tag/parent-tag that uses this just have to set the `-webkit-line-clamp` on which line it should clamp.
     * default `-webkit-line-clamp: 2`.
     */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-break: break-word;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.preventSelect {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/********** SCROLL BAR RELATED GLOBAL STYLE **********/
.scrollbar::-webkit-scrollbar,
.scrollbar-thin::-webkit-scrollbar {
    background: transparent;
    width: 0.8rem;
    height: 0.8rem;
}

.scrollbar::-webkit-scrollbar,
.scrollbar-thin::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
}

.scrollbar::-webkit-scrollbar-thumb,
.scrollbar-thin::-webkit-scrollbar-thumb {
    width: 0.1rem;
    border-radius: 3rem;
    cursor: pointer;
    background-color: transparent;
}

.scrollbar:hover::-webkit-scrollbar-thumb,
.scrollbar-thin:hover::-webkit-scrollbar-thumb {
    opacity: 0.5;
    background-color: rgba(220, 220, 220, 90);
}

::-webkit-scrollbar-corner {
    background: var(--color-neutral-white);
}

/* firefox special scroll */
@-moz-document url-prefix("") {
    .scrollbar-thin {
        scrollbar-width: thin;
    }
}

.hide-scrollbar {
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Freestar CMP */
#pmLink {
    visibility: hidden;
    text-decoration: none;
    cursor: pointer;
    background: transparent;
    border: none;
}

#pmLink:hover {
    visibility: visible;
    color: #50d2c2;
}

/* Recaptcha */
.grecaptcha-badge {
    display: none;
}

.border-on-hover:hover {
    border: 1px solid var(--color-neutral-black) !important;
}

.outline-on-hover:hover {
    outline: 1px solid var(--color-neutral-black) !important;
}

/* Pointer overrides */
.FlowModal-module__welcomeHeading___biP6e {
    /* heading4 */
    font-size: 2.5rem !important;
    font-weight: 600 !important;
    line-height: 3.4rem !important;
    font-weight: 600 !important;
}

.FlowModal-module__welcomeSubheading___F6sDJ {
    /* heading5 */
    font-size: 2rem !important;
    font-weight: 600 !important;
    line-height: 2.6rem !important;
    font-weight: 600 !important;
    margin-top: 1rem !important;
    color: var(--color-neutral-3) !important;
}

.FlowModal-module__headerSection___Pekux {
    gap: 1.5rem !important;
}

.FlowModal-module__description___wSUmz {
    font-size: 1.6rem !important;
    font-weight: 400 !important;
    line-height: 2.6rem !important;
}

.FlowModal-module__welcomeImage___HOvSu {
    height: auto !important;
}

.Dialog-module__content___3sQyM {
    border-radius: 30px !important;
}
